
import { Options, Vue } from 'vue-class-component'
import tournamentMatch from '../components/Frontend/TournamentMatch/index.vue'

@Options({
  components: {
    tournamentMatch
  }
})

export default class TournamentMatch extends Vue {}
